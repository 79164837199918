<template>
  <q-page class="home">
    <q-card>
      <q-card-section class="row justify-center items-center q-gutter-x-md">
        <q-file
          outlined
          v-model="doc"
          label="PDF"
          type="file"
          accept=".pdf"
          @update:model-value="logVal"
        ></q-file>
        <!-- <q-file outlined v-model="ttd" label="TTD" type="file"></q-file> -->
      </q-card-section>

      <q-card-section>
        <q-markup-table>
          <thead>
            <th>No</th>
            <th>Nama</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="(val, i) in data.signer" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ val.name }}</td>
              <td>
                <q-btn
                  flat
                  dense
                  label="Delete"
                  color="negative"
                  @click="deleteSigner(i)"
                ></q-btn>
              </td>
            </tr>
            <tr v-if="data.signer.length < 4">
              <td class="text-center" colspan="3">
                <q-btn
                  flat
                  label="add signer"
                  @click="dialogAddSigner = true"
                  icon="add"
                ></q-btn>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
      <!-- <q-card-section>
        <div id="pspdfkit" style="width: 100%; height: 100vh"></div>
      </q-card-section> -->
      <q-card-section>
        <q-select
          label="Edit As"
          :options="data.signer"
          map-options
          emit-value
          option-value="initial"
          option-label="name"
          @update:model-value="changePage"
          v-model="data.selUser"
        ></q-select>
      </q-card-section>
      <q-card-section v-if="mainDocImg">
        <div ref="thePage">
          <img
            @load="onImageLoad"
            ref="image_ref"
            :src="`${mainDocImg}`"
            style="width: calc(100vw - 100px); border: 1px solid black"
          />
          <canvas ref="canvas_ref" @click="moveShape"></canvas>
        </div>
      </q-card-section>
      <q-card-section class="row justify-center" style="width: 100%">
        <div
          v-for="(val, i) in data.signer"
          :key="i"
          class="column cursor-pointer"
          style="width: 150px; height: 150px; border: 1px solid black"
          @click="
            data.selSigner = val.initial;
            dialogSign = true;
          "
        >
          <div v-if="val.src" class="l-grow">
            <q-img :src="`${val.src}`"></q-img>
          </div>
          <div v-else class="l-grow"></div>
          <div class="text-center">{{ val.name }}</div>
        </div>
      </q-card-section>
      <q-card-section class="row justify-center q-gutter-x-md">
        <!-- <q-btn unelevated label="Sign" @click="loadSign"></q-btn> -->
        <q-btn unelevated label="Download" @click="downloadDoc"></q-btn>
      </q-card-section>
    </q-card>
    <q-dialog v-model="dialogSign" persistent>
      <q-card style="width: 600px">
        <q-card-section class="row justify-between items-center">
          <div class="l-text-subtitle">Sign</div>
          <q-btn icon="close" flat @click="closeDialogSign"></q-btn>
        </q-card-section>
        <q-card-section>
          <q-tabs v-model="data.tab">
            <q-tab name="type" label="Type" />
            <q-tab name="draw" label="Draw" />
            <q-tab name="upload" label="Upload" />
          </q-tabs>
          <q-tab-panels
            v-model="data.tab"
            animated
            class="shadow-2 rounded-borders"
          >
            <q-tab-panel name="type">
              <div class="column">
                <q-input outlined v-model="data.signatureText"></q-input>
                <div
                  class="sign-font q-pa-sm sign-area text-center"
                  style="font-size: 75pt"
                >
                  {{ data.signatureText }}
                </div>
                <canvas ref="canvas_type_sign" style="display: none"></canvas>
              </div>
            </q-tab-panel>

            <q-tab-panel name="draw">
              <VueSignaturePad
                class="l-grow"
                height="300px"
                ref="signaturePad"
              />
            </q-tab-panel>

            <q-tab-panel name="upload">
              <q-file
                outlined
                v-model="ttd"
                label="TTD"
                type="file"
                accept="image/*"
              ></q-file>
              <!-- @update:model-value="readUploadSign" -->
            </q-tab-panel>
          </q-tab-panels>
        </q-card-section>
        <q-card-section>
          <q-btn
            unelevated
            label="Sign"
            @click="saveSign"
            class="l-grow"
          ></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogAddSigner">
      <q-card>
        <q-card-section class="row justify-between items-center">
          <div class="l-text-subtitle">Sign</div>
          <q-btn icon="close" flat v-close-popup></q-btn>
        </q-card-section>
        <q-card-section>
          <q-input outlined label="Nama" v-model="data.newName"></q-input>
        </q-card-section>
        <q-card-section>
          <q-btn
            class="l-grow"
            label="Add Signer"
            no-caps
            color="primary"
            @click="addNewSigner"
          ></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { onMounted, reactive, ref } from "vue";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.worker.mjs";

export default {
  name: "HomeView",
  components: {},
  setup() {
    let data = reactive({
      signer: [
        {
          name: "Muhammad Al Ghiffari",
          initial: "al",
          color: "rgba(255, 0, 0, 0.5)",
          width: 100,
          height: 100,
          x: 10,
          y: 10,
          src: "",
        },
        {
          name: "Falma Zulmaulana",
          initial: "valma",
          color: "rgba(0, 255, 0, 0.5)",
          width: 100,
          height: 100,
          x: 10,
          y: 10,
          src: "",
        },
      ],
      selUser: null,
      tab: "type",
      selSigner: null,
      signatureText: "",

      colors: [
        "rgba(255, 0, 0, 0.5)",
        "rgba(0, 255, 0, 0.5)",
        "rgba(0, 0, 255, 0.5)",
        "rgba(255, 255, 0, 0.5)",
      ],

      newName: "",
    });

    let doc = ref(null);
    let ttd = ref(null);

    let image_ref = ref(null);
    let canvas_ref = ref(null);

    let signaturePad = ref(null);

    let shape = ref({
      width: 100,
      height: 100,
      x: 10,
      y: 10,
      color: "rgba(255, 0, 0, 0.5)", // Semi-transparent red
    });

    async function readFile(file) {
      let ret = await new Promise((resolve, reject) => {
        // Create file reader
        let reader = new FileReader();

        // Register event listeners
        reader.addEventListener("loadend", (e) => resolve(e.target.result));
        reader.addEventListener("error", reject);

        // Read file
        reader.readAsArrayBuffer(file);
      });
      return ret;
    }

    async function readImage(file) {
      let ret = await new Promise((resolve, reject) => {
        // Create file reader
        let reader = new FileReader();

        // Register event listeners
        reader.addEventListener("loadend", (e) => resolve(e.target.result));
        reader.addEventListener("error", reject);

        // Read file
        reader.readAsDataURL(file);
      });
      return ret;
    }

    async function addSignatureToPdf() {
      let theDoc = await readFile(doc.value);
      let theImg = await readFile(ttd.value);

      const pdfDoc = await PDFDocument.load(theDoc);
      const img = await pdfDoc.embedPng(theImg);

      const page = pdfDoc.getPage(0);
      page.drawImage(img, {
        x: page.getWidth() / 2 - img.width / 2,
        y: page.getHeight() / 2 - img.height / 2,
        width: img.width,
        height: img.height,
      });

      const pdfBytes = await pdfDoc.save();
      // const pdfBytes = await pdfDoc.save();

      // Convert the PDF bytes to a Blob
      //   const blob = new Blob([pdfBytes], { type: "application/pdf" });

      //   saveAs(blob, "name.pdf");
    }

    async function onImageLoad() {
      return;
      const image = image_ref.value;
      const canvas = canvas_cref.value;
      const ctx = canvas.getContext("2d");

      // Adjust canvas size to match the image
      canvas.width = image.width;
      canvas.height = image.height;

      // Position the canvas over the image
      canvas.style.position = "absolute";
      canvas.style.left = image.offsetLeft + "px";
      canvas.style.top = image.offsetTop + "px";

      // Now, draw a rectangle (or any other shape)
      //   ctx.fillStyle = "rgba(255, 0, 0, 0.5)"; // Semi-transparent red
      //   ctx.fillRect(10, 10, 100, 100); // Example: x, y, width, height
      for (let i in data.signer) {
        let user = data.signer[i];
        drawShape(user);
      }
    }

    function drawShape(user) {
      const canvas = canvas_ref.value;
      const ctx = canvas.getContext("2d");

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the shape at new position
      ctx.fillStyle = user.color;
      ctx.fillRect(user.x, user.y, user.width, user.height);
    }

    async function moveShape(event) {
      // Calculate click position relative to canvas
      const rect = canvas_ref.value.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      let idx = data.signer.findIndex((el) => el.initial == data.selUser);
      if (idx == -1) return;
      data.signer[idx].x = x - data.signer[idx].width / 2;
      data.signer[idx].y = y - data.signer[idx].height / 2;

      // Redraw the shape at click position
      drawShape(data.signer[idx]);
    }

    let overlayImageSrc = ref(`./sign.png`);
    let overlayImage = ref(new Image());

    onMounted(() => {
      overlayImage.value.src = overlayImageSrc.value;

      const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;

      // PSPDFKit.load({
      //   baseUrl,
      //   container: "#pspdfkit",
      //   document: "ioniq.pdf",
      // });
    });

    let changePage = (val) => {
      let user = data.signer.find((el) => el.initial == val);
      const image = image_ref.value;
      const canvas = canvas_ref.value;
      const ctx = canvas.getContext("2d");

      // Adjust canvas size to match the image
      canvas.width = image.width;
      canvas.height = image.height;

      // Position the canvas over the image
      canvas.style.position = "absolute";
      canvas.style.left = image.offsetLeft + "px";
      canvas.style.top = image.offsetTop + "px";

      drawShape(user);
    };

    async function loadSign() {
      const canvas = canvas_ref.value;
      const ctx = canvas.getContext("2d");

      // Clear the canvas
      await ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the overlay image within the shape's boundaries
      await ctx.drawImage(
        overlayImage.value,
        shape.value.x,
        shape.value.y,
        shape.value.width,
        shape.value.height
      );
    }

    let saveSign = async () => {
      if (data.tab == "type") {
        console.log("type");
        await getTypeSign();
      } else if (data.tab == "draw") {
        console.log("draw");
        await downloadSign();
      } else {
        console.log("upload");
        await readUploadSign();
      }
      await pasteSign();
      closeDialogSign();
    };

    let pasteSign = async () => {
      data.selUser = null;
      const canvas = canvas_ref.value;
      const ctx = canvas.getContext("2d");

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i in data.signer) {
        let user = data.signer[i];
        console.log("error on paste");

        if (user.src != "") {
          let img = new Image();
          img.src = user.src;
          img.onload = async function () {
            await ctx.drawImage(img, user.x, user.y, user.width, user.height);
          };
        }
      }
    };

    let canvas_type_sign = ref(null);
    let getTypeSign = async () => {
      let idx = data.signer.findIndex((el) => el.initial == data.selSigner);
      const canvas = canvas_type_sign.value;
      if (!canvas) return;

      canvas.width = 300;
      canvas.height = 150;

      const ctx = canvas.getContext("2d");

      ctx.font = "75pt sign";
      ctx.fillStyle = "#000";

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillText(data.signatureText, 10, canvas.height / 2 + 25); // Adjust positioning as needed

      data.signer[idx].src = canvas.toDataURL("image/png");
    };

    async function downloadSign() {
      let idx = data.signer.findIndex((el) => el.initial == data.selSigner);
      let pad = signaturePad.value;
      const result = pad.saveSignature();
      if (!result.isEmpty) {
        data.signer[idx].src = result.data;
      }
      dialogSign.value = false;
    }

    async function readUploadSign() {
      let idx = data.signer.findIndex((el) => el.initial == data.selSigner);
      // let url = await readImage(file);
      let url = URL.createObjectURL(ttd.value);

      data.signer[idx].src = url;
      ttd.value = null;
    }

    async function closeDialogSign() {
      data.tab = "type";
      data.signatureText = "";
      dialogSign.value = false;
    }

    let mainDocImg = ref(null);
    let logVal = async (file) => {
      console.log(file);
      const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
      const canvas = document.createElement("canvas");
      const pageNumber = 1; // Example: convert only the first page
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport: viewport }).promise;

      // Here you have the canvas with your PDF page rendered, you can convert it to an image URL or blob as needed
      // Example: Convert canvas to image URL
      const imageUrl = canvas.toDataURL("image/png");

      // Do something with the imageUrl (display it, download it, etc.)
      mainDocImg.value = imageUrl;
    };

    let addNewSigner = () => {
      let payload = {
        name: data.newName,
        initial: data.newName.slice(0, 3),
        color: data.colors[data.signer.length],
        width: 100,
        height: 100,
        x: 10,
        y: 10,
        src: "",
      };
      data.signer.push(payload);
      dialogAddSigner.value = false;
    };

    let deleteSigner = (idx) => {
      data.signer.splice(idx, 1);
    };

    let downloadDoc = async () => {
      const theDoc = new jsPDF("p", "mm", "a4");
      // let mypage = "await html2canvas(thePage.value, { scale: 2 })";
      let mypage = await html2canvas(thePage.value, { scale: 2 });
      theDoc.addImage(mypage, "JPEG", 0, 0, 210, 297, `page1`, "MEDIUM");
      theDoc.save(`signed.pdf`);
    };

    let dialogSign = ref(false);
    let dialogAddSigner = ref(false);
    let thePage = ref(null);

    return {
      doc,
      ttd,
      data,
      addSignatureToPdf,
      readFile,
      addSignatureToPdf,
      onImageLoad,
      canvas_ref,
      image_ref,
      moveShape,
      shape,
      loadSign,
      changePage,
      dialogSign,
      signaturePad,
      downloadSign,
      logVal,
      readUploadSign,
      closeDialogSign,
      canvas_type_sign,
      saveSign,
      dialogAddSigner,
      addNewSigner,
      deleteSigner,
      thePage,
      downloadDoc,
      mainDocImg,
    };
  },
};
</script>
<style scoped>
div {
  position: relative;
}
canvas {
  position: absolute;
}
</style>
